import './rightPane.css'

const RightPane = () => {
  return (
    <div className="right-pane">

      <div className="box1">
        <br />
      <footer>
   <button className="account">
     <div className="photo">
       <img
         alt='my image'
         src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Elon_Musk_%283017880307%29.jpg"
       />
     </div>
     <div>
       <div className="name">Irfan</div>
       <div className="username">@irfan897</div>
     </div>
   </button>
   </footer>
   
   <footer>
   <button className="account">
     <div className="photo">
       <img
         alt='my image'
         src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Elon_Musk_%283017880307%29.jpg"
       />
     </div>
     <div>
       <div className="name">Irfan</div>
       <div className="username">@irfan897</div>
     </div>
   </button>
   </footer>
   <footer>
   <button className="account">
     <div className="photo">
       <img
         alt='my image'
         src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Elon_Musk_%283017880307%29.jpg"
       />
     </div>
     <div>
       <div className="name">Irfan</div>
       <div className="username">@irfan897</div>
     </div>
   </button>
   </footer>


      </div>
      <div className="box2">
      <footer>
   <button className="account">
     <div className="photo">
       <img
         alt='my image'
         src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Elon_Musk_%283017880307%29.jpg"
       />
     </div>
     <div>
       <div className="name">Irfan</div>
       <div className="username">@irfan897</div>
     </div>
   </button>
   </footer>
   <footer>
   <button className="account">
     <div className="photo">
       <img
         alt='my image'
         src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Elon_Musk_%283017880307%29.jpg"
       />
     </div>
     <div>
       <div className="name">Irfan</div>
       <div className="username">@irfan897</div>
     </div>
   </button>
   </footer>
        
      </div>
    </div>
  )
}

export default RightPane
