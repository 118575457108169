import React from 'react'
import './style.css'
const Profile = () => {
  return (
    <>
    <div className="name">Irfan</div>
    <div style={{color:'white'}} className='profile'>
    <div style={{color:'white'}} className='profile'>
      </div>
    <footer>
   <button className="account">
     <div className="photo">
       <img
         alt='my image'
         src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Elon_Musk_%283017880307%29.jpg"
       />
     </div>
     <div>
       <div className="name">Irfan</div>
       <div className="username">@irfan897</div>
     </div>
   </button>
   </footer>
    



      </div>
      <div style={{color:'white'}} className='profile'></div>
    </>
   
  )
}

export default Profile